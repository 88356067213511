import type { IViewModel } from 'controller/types';

export function initializeIntegrations(vm: IViewModel) {
  return Promise.all([
    vm._.pricingPlans.init(),
    vm._.comments.init({
      shouldAutoBindStateToSetProps: true,
    }),
  ]);
}
