import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
} from '@wix/tpa-settings';

export const settings = {
  // @deprecated use styleParam instead
  showMembersBadges: createSettingsParam('showMembersBadges', {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  }),
};

export const styles = {
  showMembersBadges: createStylesParam('showMembersBadges', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};
