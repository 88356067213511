import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
} from '@wix/tpa-settings';

import { transformSettingsKey } from '../helpers';

export const settings = {
  // @deprecated use styleParam instead
  showMemberCount: createSettingsParam('showMemberCount', {
    getDefaultValue: () => true,
    type: SettingsParamType.Boolean,
    dangerousKeyTransformationOverride: transformSettingsKey,
  }),

  // @deprecated use styleParam instead
  showGroupType: createSettingsParam('showGroupType', {
    getDefaultValue: () => true,
    type: SettingsParamType.Boolean,
  }),
};

export const styles = {
  showMemberCount: createStylesParam('showMemberCount', {
    getDefaultValue: () => true,
    type: StyleParamType.Boolean,
  }),

  showGroupType: createStylesParam('showGroupType', {
    getDefaultValue: () => true,
    type: StyleParamType.Boolean,
  }),
};
